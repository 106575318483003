import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
const _ = require("lodash");

const tagLink = tag => {
  return (`/tags/${_.kebabCase(tag)}/`)
}

const TagsPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const tags = data.allWpTag.nodes
  const tagCount = data.allWpTag.totalCount

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Tags" />
      <h1>{tagCount} tags</h1>
      <div class="tag-container">
      {data.allWpTag.nodes.length > 0 && data.allWpTag.nodes.map(elem => <span class="tag"><Link to={tagLink(elem.name)}>{elem.name} <span class ="tag-count">{elem.count}</span></Link></span>)}
      </div>
    </Layout>
  )
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allWpTag(sort: {order: DESC, fields: count}) {
        totalCount
        nodes {
          name
          count
        }
      }
  }
`
